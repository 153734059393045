import { TUserResponseType } from 'typings/userTypes';
import { homeSecurityPortalApi } from 'data/axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function GetUserDetails(
  authToken?: string
): Promise<ApiResponse<TUserResponseType>> {
  try {
    const response = await homeSecurityPortalApi.get(endpoints.getUser, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    console.log('API Response:', response); // Log response to inspect structure
    return response;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error; // Ensure the error is propagated correctly
  }
}

export async function GetOneTimeToken() {
  const response = await homeSecurityPortalApi.get(endpoints.oneTimeToken);
  return response;
}
