import { TDeviceResponseType, TSpaceResponseDataV2 } from 'typings';
import { homeSecurityPortalApi } from 'data/axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function GetAllSpaces(): Promise<
  ApiResponse<TSpaceResponseDataV2[]>
> {
  const response = await homeSecurityPortalApi.get(endpoints.getAllSpaces, {
    headers: {
      'Accept-Version': 'v2'
    }
  });
  console.log('GetAllSpaces', response);
  return response;
}

export async function getAllDeviceBySpaceId(
  spaceId: string
): Promise<ApiResponse<{ items: TDeviceResponseType[] }>> {
  const response = await homeSecurityPortalApi.get(
    endpoints.getAllDeviceBySpaceIdUrl(spaceId)
  );
  console.log('getAllDeviceBySpaceId', response);
  return response;
}
