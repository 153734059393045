/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Invoice from 'assets/invoice.png';
import InstaCard from 'components/InstaCard';
import SkeletonLoader from 'components/SkeletonLoader';
import { homeSecurityPortalApi } from 'data/axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import { GetPaymentMethods } from 'services/api/paymentsApi';
import { GetAllSpaces } from 'services/api/SpacesApi';
import {
  changePaymentMethod,
  getSubscriptionDetails
} from 'services/api/subscriptionApi';
import { Image } from 'styles';
import {
  SpaceRole,
  TCard,
  TPaymentMethods,
  TSpaceResponseData,
  TSubscriptionType,
  TTransaction
} from 'typings';
import { getCardImageProps } from 'utils/commonFunctions';
import { SUBSCRIPTION_TYPE } from '../../typings/purchaseTypes';

function billingAndInvoiceSummary() {
  const navigate = useNavigate();

  const [transactionList, setTransactionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [paymentOption, setPaymentOption] = useState<TPaymentMethods[]>([]);
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [isTransactionsFetched, setIsTransactionsFetched] = useState(false);
  const [loadErr, setLoadErr] = useState('');
  const [page, setPage] = useState(1);
  const [subscriptionCard, setSubscriptionCard] = useState<TCard>();
  const [selectedCardId, setSelectedCardId] = useState('');
  const pageSize = 5;
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);
  const [subscriptionData, setSubscriptionList] = useState<TSubscriptionType[]>(
    []
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.billing'
  });

  const fetchSavedCards = () => {
    setIsPaymentLoading(true);

    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
      })
      .catch((err) => console.log('err payment_methods', err))
      .finally(() => {
        setIsPaymentLoading(false);
      });
  };

  const addNewPaymentMethod = () => {
    navigate('/billings/new');
  };

  useEffect(() => {
    setIsLoading(
      isPaymentLoading || isSubscriptionLoading || isTransactionsLoading
    );
  }, [isPaymentLoading, isSubscriptionLoading, isTransactionsLoading]);

  const fetchSubscriptionAndSpacesData = async () => {
    try {
      const [subscriptionsData, allSpacesResponse] = await Promise.all([
        getSubscriptionDetails(),
        GetAllSpaces()
      ]);

      // Extract and return relevant data
      const ownerSpace = allSpacesResponse.data.filter(
        (item) => item.role === SpaceRole.OWNER
      );

      return {
        subscriptionCard: subscriptionsData.data[0]?.card_details,
        subscriptionList: subscriptionsData.data,
        spaceData: ownerSpace
      };
    } catch (error) {
      console.log('Error fetching subscription and space data:', error);
      throw error; // Re-throw error for handling in the caller
    }
  };

  const fetchData = async () => {
    setIsSubscriptionLoading(true);
    try {
      const {
        subscriptionCard: newSubscriptionCard,
        subscriptionList: newSubscriptionList,
        spaceData: newSpaceData
      } = await fetchSubscriptionAndSpacesData();

      // Update the existing state variables
      setSubscriptionCard(newSubscriptionCard);
      setSubscriptionList(newSubscriptionList);
      setSpaceData(newSpaceData);
    } catch (error) {
      // Handle errors
      console.error('Failed to fetch data:', error);
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (subscriptionCard === undefined) {
      return;
    }
    // Check if there's a card that matches the subscriptionCard id
    let selectedCard = paymentOption.find(
      (el: {
        id: string;
        card: {
          id: string;
          exp_month: number;
          exp_year: number;
          last4: string;
        };
      }) => el.card.id === subscriptionCard?.id
    );

    // If no card with matching id is found, check based on exp_month, exp_year, and last4
    if (!selectedCard) {
      selectedCard = paymentOption.find(
        (el: {
          id: string;
          card: {
            id: string;
            exp_month: number;
            exp_year: number;
            last4: string;
          };
        }) =>
          el.card.exp_month === subscriptionCard?.exp_month &&
          el.card.exp_year === subscriptionCard?.exp_year &&
          el.card.last4 === subscriptionCard?.last4
      );
    }

    const id = selectedCard?.id || ''; // Default to empty string if no match
    setSelectedCardId(id);
  }, [paymentOption, subscriptionCard]);

  const LoadMoreDetails = useCallback(() => {
    setIsTransactionsLoading(true);
    setIsTransactionsFetched(false);

    homeSecurityPortalApi
      .get(endpoints.getTransactionsUrl((page - 1) * pageSize))
      .then((res) => {
        if (res.data !== null) {
          setTransactionList((state) => state.concat(res.data.items));
          setTotalTransactionsCount(res.data.total_count);
        } else {
          setLoadErr('No more payments to load');
        }
      })
      .catch((err) => {
        console.log('purchase', err);
        setLoadErr('No more payments to load');
      })
      .finally(() => {
        setIsTransactionsFetched(true);
        setIsTransactionsLoading(false);
      });
  }, []);

  const downloadInvoice = (transaction_id: string) => {
    homeSecurityPortalApi
      .get(endpoints.billings(transaction_id))
      .then((res) => {
        // console.log('download success', res.data);
        window.location.href = res?.data?.invoice_link;
      })
      .catch((err) => {
        console.log('download error', err);
      });
  };

  const handleCreditCardChange = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setIsSubscriptionLoading(true);
    //  Make API call to update payment method
    const payload = {
      stripe_payment_method: (event.target as HTMLInputElement).value
    };

    changePaymentMethod(subscriptionData[0]?.id, payload)
      .then((res) => {
        setSelectedCardId((event.target as HTMLInputElement).value);
      })
      .catch((err) => {
        // TODO: Add failure toast message
        console.error(err);
      })
      .finally(() => {
        setIsSubscriptionLoading(false);
      });

    // Note: Data is not reloaded here.
    // The actual change occurs in the Piggyback service.
    // Subscription data is sourced from the Atlas service.
    // It may take a few seconds for changes to reflect in the other service.
  };

  useEffect(() => {
    if (isLoading && !isTransactionsFetched) {
      LoadMoreDetails();
    }
    if (isLoading) {
      fetchSavedCards();
    }
  }, []);

  if (isLoading) {
    // return <InstaSpinner withWrapper />;
    return (
      <Stack gap="20px">
        <Skeleton
          variant="rounded"
          height={210}
          animation="pulse"
          sx={{ borderRadius: '20px' }}
        />
        <Stack justifyContent="space-between" direction="row">
          <Skeleton
            variant="rectangular"
            height={25}
            width="30%"
            animation="pulse"
            sx={{ borderRadius: '8px' }}
          />
          <Skeleton
            variant="rectangular"
            height={25}
            width="20%"
            animation="pulse"
            sx={{ borderRadius: '8px' }}
          />
        </Stack>

        <Skeleton
          variant="rounded"
          height={170}
          animation="pulse"
          sx={{ borderRadius: '20px' }}
        />
        <Skeleton
          variant="rounded"
          height={25}
          animation="pulse"
          sx={{ borderRadius: '8px' }}
        />
        <Skeleton
          variant="rounded"
          height={130}
          animation="pulse"
          sx={{ borderRadius: '20px' }}
        />
      </Stack>
    );
  }
  return (
    <>
      {spaceData.length > 0 && !isLoading && (
        <>
          {subscriptionData[0]?.subscription_type !==
            SUBSCRIPTION_TYPE.PREVIEW && (
            <InstaCard margin="20px" key={subscriptionData[0]?.id}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                marginBottom="20px"
              >
                <Typography variant="h4">{t('subscription')}</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData[0]?.sku_name || t('na')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                margin="20px 0"
              >
                <Typography variant="h4">{t('nextPayment')}</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData.length > 0
                    ? dayjs(subscriptionData[0]?.next_billing_date).format(
                        'MMM D, YYYY'
                      )
                    : t('na')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                marginTop="20px"
              >
                <Typography variant="h4">{t('amount')}</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData.length > 0 &&
                  subscriptionData[0]?.next_bill_amount
                    ? `$${subscriptionData[0]?.next_bill_amount / 100}`
                    : t('na')}
                </Typography>
              </Stack>
            </InstaCard>
          )}
          {subscriptionData[0]?.subscription_type ===
            SUBSCRIPTION_TYPE.PREVIEW && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="60px"
              marginBottom="20px"
              sx={{
                borderRadius: '20px',
                background: '#EDEDED',
                position: 'relative',
                padding: '20px 20px',
                boxSizing: 'border-box',
                boxShadow: 'none',
                marginBottom: '20px',
                overflow: 'hidden'
              }}
            >
              <Typography variant="h4">{t('subscription')}</Typography>
              <Typography variant="Body1SemiBold">
                {subscriptionData[0]?.sku_name || t('na')}
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="16px"
          >
            <Typography variant="h3">{t('paymentMethod')}</Typography>
            <Button
              variant="text"
              sx={{ padding: '0px' }}
              onClick={addNewPaymentMethod}
            >
              {t('addNew')}
            </Button>
          </Stack>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedCardId}
            onChange={handleCreditCardChange}
          >
            <InstaCard>
              {paymentOption.map(
                (el: {
                  id: string;
                  card: {
                    id: string;
                    brand: string;
                    last4: string;
                    exp_month: number;
                    exp_year: number;
                  };
                }) => {
                  const {
                    id,
                    card: { last4, brand, exp_month, exp_year }
                  } = el;
                  const { src, width, height } = getCardImageProps(brand);
                  return (
                    <FormControlLabel
                      key={id}
                      value={id}
                      control={<Radio />}
                      labelPlacement="start"
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: (theme) => theme.spacing(2, 0)
                      }}
                      label={
                        <Stack direction="row" alignItems="center" gap={3}>
                          <Image src={src} width={width} height={height} />
                          <Stack gap={1.5}>
                            <Typography variant="h4">
                              **** **** **** {last4}
                            </Typography>
                            <Typography
                              variant="Body1Medium"
                              color="text.shadedText"
                            >{`Expiry date ${exp_month}/${exp_year}`}</Typography>
                            {id === selectedCardId && (
                              <Typography variant="Body1Medium" color="#007BFF">
                                {t('cardUseNote')}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      }
                    />
                  );
                }
              )}
            </InstaCard>
          </RadioGroup>
          <Typography variant="h3" marginBottom="16px">
            {t('transactionHistory')}
          </Typography>
          <TableContainer>
            {isTransactionsFetched === true && transactionList.length > 0 && (
              <Table
                sx={{
                  minWidth: 650,
                  border: '2px solid #F0F4F4',
                  borderRadius: '8px'
                }}
              >
                <TableHead sx={{ border: 0 }}>
                  <TableRow>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('transaction')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('status')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('type')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('amount')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">
                        {t('transactionTime')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList.map((transaction: TTransaction) => (
                    <TableRow
                      key={transaction.id}
                      sx={{
                        border: 0,
                        '&:nth-child(odd)': { background: '#F0F4F4' }
                      }}
                    >
                      <TableCell
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                      >
                        {transaction.sku_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: 0
                        }}
                        align="justify"
                      >
                        <Typography variant="Body1SemiBold">
                          {transaction.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="justify"
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                        onClick={() => downloadInvoice(transaction.id)}
                      >
                        <Stack
                          borderRadius="8px"
                          maxHeight="28px"
                          justifyContent="center"
                          sx={{ background: '#F8F38B' }}
                        >
                          <Image
                            src={Invoice}
                            height="16"
                            width="16"
                            style={{ marginRight: 10 }}
                          />

                          <Typography variant="Body1SemiBold">
                            {transaction.status === 'Success' ? 'Invoice' : ''}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          ${transaction.amount / 100}
                        </Typography>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          {' '}
                          {dayjs(transaction.transaction_time).format(
                            'YYYY.MM.DD HH:mm:ss'
                          )}{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          {loadErr === '' &&
            isTransactionsFetched === true &&
            totalTransactionsCount > page * pageSize && (
              <Stack
                justifyContent="center"
                onClick={() => setPage((state) => state + 1)}
              >
                <Typography variant="Body1SemiBold">{t('loadMore')}</Typography>
                <ExpandMoreIcon />
              </Stack>
            )}
          {loadErr === '' &&
            isTransactionsFetched === true &&
            totalTransactionsCount === 0 && (
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="Body1SemiBold" sx={{ cursor: 'auto' }}>
                  {t('noTransactions')}
                </Typography>
              </Stack>
            )}
          {loadErr !== '' && (
            <Stack justifyContent="center">
              <Typography variant="Body1SemiBold">{loadErr}</Typography>
            </Stack>
          )}
        </>
      )}

      {spaceData.length === 0 && !isLoading && (
        <InstaCard>
          {[
            <Stack alignItems="center" key="1010">
              <Typography variant="h3">{t('noSpaceCreated')}</Typography>
            </Stack>
          ]}
        </InstaCard>
      )}
    </>
  );
}

export default billingAndInvoiceSummary;
