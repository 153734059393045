/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import urls from 'configs/urls';
import { getPartnerName } from 'partner_configuration';
import { LogoutUser } from 'store/store';
import { getLocalStorageItem } from 'utils/commonFunctions';
import {
  clearSession,
  getSessionStorageItem,
  refreshSession,
  setSessionStorageItem
} from 'utils/sessionFunctions';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create multiple axios instances with different base URLs for easier
 * communication with different services.
 * Base URLs are dependent on current environment.
 * */

const homeSecurityPortalApi: AxiosInstance = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

homeSecurityPortalApi.interceptors.request.use((request) => {
  if (request && request.headers) {
    if (!request.headers['skip-auth']) {
      // Add Authorization, Partner-ID, and Client-ID headers if 'skip-auth' is not set
      request.headers['Authorization'] = `Bearer ${getLocalStorageItem(
        'auth-token'
      )}`;
    }
    const url = window.location.origin;
    // console.log('url::', url);
    const partnerName = getPartnerName(url);
    // console.log(':: partnerName', partnerName);
    request.baseURL = urls.baseUrl;
    request.headers['Partner-ID'] = partnerName;
    request.headers['Client-ID'] = 'home-user-portal';
    // Get session ID from sessionStorage
    let sessionID = getSessionStorageItem('session-id');

    // Check if session is expired or not present
    if (!sessionID) {
      sessionID = refreshSession(); // Refresh session if expired
      setSessionStorageItem('session-id', sessionID); // Save new session ID
    }

    // Set the session ID and request ID in the headers
    request.headers['X-Session-ID'] = sessionID;
    request.headers['X-Request-ID'] = uuidv4();
  }
  return request;
});

homeSecurityPortalApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const originalRequest = error.config;

    // Check if error.response exists before accessing its properties
    if (error.response) {
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true;
      //   // Add refresh token logic here
      //   // Example: await refreshAccessToken();
      //   // for now we logging out user when recieve 401 or 403 status code
      //   // clearSession();
      //   // LogoutUser();
      // }

      // if (error.response.status === 403) {
      //   console.error('403 Forbidden error:', error.response.data);
      //   // Handle forbidden access case here
      //   // clearSession();
      //   // LogoutUser();
      // }
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('errInterceptor perform logout');
        clearSession();
        LogoutUser();
      }
    } else {
      console.error('Error: ', error.message);
    }

    return Promise.reject(error); // Reject with error to maintain promise chain
  }
);

export { homeSecurityPortalApi };
