import { homeSecurityPortalApi } from 'data/axios';
import { TSetupIntent, TPaymentMethodsList } from 'typings';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function GetPaymentMethods(): Promise<
  ApiResponse<TPaymentMethodsList>
> {
  const response = await homeSecurityPortalApi.get(endpoints.paymentMethodsUrl);
  return response;
}

export async function PaymentSetupIntent(): Promise<ApiResponse<TSetupIntent>> {
  const response = await homeSecurityPortalApi.post(endpoints.setupIntentUrl);
  return response;
}
