import { Button } from '@mui/material';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  StripeElements,
  StripeError,
  StripePaymentElementChangeEvent
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { GetPaymentMethods } from 'services/api/paymentsApi';
import { TProps_PaymentScreen } from 'typings';

export default function StripeForm(props: TProps_PaymentScreen) {
  const { submitPurchaseRequest, amount, isUpgrade, isFreeTrial } = props;
  const stripe = useStripe();
  const elements: StripeElements | null = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setErrorMessage('');
    setLoading(true);

    // Trigger form validation and wallet collection
    if (!elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      console.log('setupIntent', setupIntent);
      const { payment_method } = setupIntent || {};
      if (typeof payment_method === 'string') {
        submitPurchaseRequest(payment_method);
      }
      // alert('payment success');
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);
  };

  useEffect(() => {
    GetPaymentMethods()
      .then((res) => {
        console.log('payment_methods', res);
      })
      .catch((err) => console.log('err payment_methods', err));
  }, []);

  const handleClearError = (event: StripePaymentElementChangeEvent) => {
    if (event.complete) {
      setErrorMessage('');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <AddressElement
          options={{
            mode: 'billing',
            autocomplete: { mode: 'disabled' },
            fields: { phone: 'auto' }
          }}
        />
        <PaymentElement onChange={handleClearError} />

        {amount < 0 ? (
          <Button
            sx={{ width: '100%', marginTop: '20px' }}
            variant="contained"
            type="submit"
            disabled={!stripe || loading}
          >
            Add Card
          </Button>
        ) : (
          <Button
            sx={{ width: '100%', marginTop: '20px' }}
            variant="contained"
            type="submit"
            disabled={!stripe || loading}
          >
            {isFreeTrial && !isUpgrade && 'Confirm Trial'}
            {isFreeTrial && isUpgrade && 'Upgrade Free Trial'}
            {!isFreeTrial && `Pay ${amount && amount / 100}`}
          </Button>
        )}

        {errorMessage && (
          <div style={{ margin: '15px auto', color: '#cc333f' }}>
            {errorMessage}
          </div>
        )}
      </form>
    </div>
  );
}
