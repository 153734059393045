import envConfig from 'configs/envConfig';

const url: string = envConfig('domainUrl') || '';

export const PARTNER_NAME = {
  LUNA_DEV: 'https://luna-home-dev.instavision.ai',
  LUNA_STAGE: 'https://luna-home-staging.instavision.ai',
  LUNA_PROD: 'https://home.lunahomeai.com',
  LOCAL: 'http://local.instaview.ai',
  LOCAL_HTTPS: 'https://local.instaview.ai',
  DEFAULT: url
};

export const PARTNER_SHORT_NAME = {
  [PARTNER_NAME.DEFAULT]: 'instaview',
  [PARTNER_NAME.LOCAL]: 'instaview',
  [PARTNER_NAME.LOCAL_HTTPS]: 'instaview',
  [PARTNER_NAME.LUNA_DEV]: 'luna',
  [PARTNER_NAME.LUNA_STAGE]: 'luna',
  [PARTNER_NAME.LUNA_PROD]: 'luna'
};

export const PARTNER_UNIVERSAL_LINK = {
  [PARTNER_NAME.LUNA_DEV]: envConfig('universalLink'),
  [PARTNER_NAME.LUNA_STAGE]: envConfig('universalLink'),
  [PARTNER_NAME.LUNA_PROD]: envConfig('universalLink'),
  [PARTNER_NAME.DEFAULT]: envConfig('universalLink'),
  [PARTNER_NAME.LOCAL]: `${window.location.protocol}//${window.location.host}`
};
