/* eslint-disable @typescript-eslint/no-explicit-any */

import { homeSecurityPortalApi } from 'data/axios';
import {
  TSubscriptionType,
  TSubscriptionBuyType,
  TUpgradPayload,
  TTrialResponse,
  TChangePaymentMethod,
  TChangePaymentMethodRes
} from 'typings';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function getSubscriptionDetails(): Promise<
  ApiResponse<TSubscriptionType[]>
> {
  const response = await homeSecurityPortalApi.get(endpoints.subscriptionUrl);
  return response;
}

export async function UpgradeUserSubscription(
  payload: TUpgradPayload
): Promise<ApiResponse<TSubscriptionBuyType>> {
  const response = await homeSecurityPortalApi.post(
    endpoints.subscriptionUpgradeUrl,
    payload
  );
  return response;
}

export async function BuyUserSubscription(
  payload: TUpgradPayload
): Promise<ApiResponse<TSubscriptionBuyType>> {
  const response = await homeSecurityPortalApi.post(
    endpoints.subscriptionBuyUrl,
    payload
  );
  return response;
}

export async function changePaymentMethod(
  subscriptionId: string,
  payload: TChangePaymentMethod
): Promise<ApiResponse<TChangePaymentMethodRes>> {
  const response = await homeSecurityPortalApi.patch(
    endpoints.changePaymentMethodUrl(subscriptionId),
    payload
  );
  return response;
}

export async function checkFreeTrialEligibility(): Promise<
  ApiResponse<TTrialResponse>
> {
  const response = await homeSecurityPortalApi.get(endpoints.checkTrialUrl);
  return response;
}

export async function cancelFreeTrialSubscription(subscriptionId: any) {
  const url = `${endpoints.subscriptionBuyUrl}/${subscriptionId}`;
  const response = await homeSecurityPortalApi.delete(url);
  return response;
}

export const fetchCurrentPlanDetails = async (skuId: string) => {
  const response = await homeSecurityPortalApi.get(
    endpoints.getCurrentPlanDetails(skuId)
  );
  return response;
};
