import { v4 as uuidv4 } from 'uuid';
// Utility to get items from sessionStorage
export function getSessionStorageItem(key: string): string | null {
  return sessionStorage.getItem(key);
}

// Utility to set items in sessionStorage
export function setSessionStorageItem(key: string, value: string): void {
  sessionStorage.setItem(key, value);
}

// Refresh the session (e.g., generate a new session ID)
export function refreshSession(): string {
  const newSessionID = uuidv4(); // Generate a new session ID
  setSessionStorageItem('session-id', newSessionID); // Save the new session ID
  return newSessionID;
}

// Clear the session (manually or on logout)
export function clearSession(): void {
  sessionStorage.removeItem('session-id');
}
