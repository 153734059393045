// import { AxiosInterceptor } from 'navigation/Interceptor';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n/i18n';
import reportWebVitals from './reportWebVitals';
import './sentry/index';
import { store } from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    {/* <AxiosInterceptor> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </AxiosInterceptor> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
