import * as Sentry from '@sentry/react';
import envConfig from 'configs/envConfig';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

if (envConfig('env') === 'prod' || envConfig('env') === 'staging') {
  Sentry.init({
    dsn: 'https://67b4cbdeb9d58f83fb9855877e73191f@o4507616560414720.ingest.us.sentry.io/4507616658063360',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      // Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    environment: envConfig('env'),
    enabled: envConfig('env') === 'prod' || envConfig('env') === 'staging',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      console.log(event, hint);
      return event;
    }
  });
}
