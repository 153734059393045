/* eslint-disable @typescript-eslint/no-explicit-any */
import { homeSecurityPortalApi } from 'data/axios';
import { signOut } from 'firebase/auth';
import { auth } from 'configs/firebaseConfig';
import dayjs from 'dayjs';
import { getPartnerName } from 'partner_configuration';
import VisaCard from 'assets/cardLogos/visa.png';
import MasterCard from 'assets/cardLogos/mastercard.png';
import AmexCard from 'assets/cardLogos/amex.png';
import DiscoverCard from 'assets/cardLogos/discover.png';
import CreditCard from 'assets/cardLogos/credit-card-regular.svg';
import { FREE_TRIAL_DURATION } from './constants';

const firebaseLogout = () => {
  signOut(auth);
};

export const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const setRequestHeaders = (authToken: string) => {
  // const url: any = window.location.origin;
  // const partnerName = getPartnerName(url);
  setLocalStorageItem('auth-token', authToken);
  homeSecurityPortalApi.defaults.headers[
    'Authorization'
  ] = `Bearer ${authToken}`;
  // homeSecurityPortalApi.defaults.headers['Partner-ID'] = partnerName;
  // homeSecurityPortalApi.defaults.headers['Client-ID'] = 'web';
};

export const removeRequestHeaders = () => {
  // removeLocalStorageItem('auth-token');

  delete homeSecurityPortalApi.defaults.headers['Authorization'];
  delete homeSecurityPortalApi.defaults.headers['Partner-ID'];
  delete homeSecurityPortalApi.defaults.headers['Client-ID'];
  delete homeSecurityPortalApi.defaults.headers['X-Session-ID'];
  delete homeSecurityPortalApi.defaults.headers['X-Request-ID'];
  localStorage.clear();

  firebaseLogout();
};

type PlanData = {
  sku_id: string;
  level: number;
  name: string;
};

const planData: PlanData[] = [
  { sku_id: 'ZJkWuV4hp9eUn7XOWpsgEpSmVfz', level: 1, name: 'Basic plan' },
  { sku_id: 'ZJkWuXu0cnmul6yD1mjsYZqdY5Z', level: 1, name: 'Basic plan' },
  { sku_id: 'ZJkWubZWLzeLEtN4sOJcg37RWCY', level: 2, name: 'Multi-Standard' },
  { sku_id: 'ZJkWuVTRkISYUmL0izTsM5N19pt', level: 2, name: 'Multi-Standard' },
  {
    sku_id: 'ZJkWuUCZH1GGokomjgTV8c4KtsI',
    level: 3,
    name: 'Premium Security'
  },
  {
    sku_id: 'ZJkWuZ02nDssfHl91aUO3AXxLxH',
    level: 3,
    name: 'Premium Security'
  },
  { sku_id: 'ZJkWuWg4Wa0jreEthneCxO1rZPn', level: 4, name: 'Combo' },
  { sku_id: 'ZJkWuXRsXMgDVk6AMbMTBliGijd', level: 4, name: 'Combo' }
];

export function getPlanNameByLevel(level: number): string {
  const plan = planData.find((item) => item.level === level);
  return plan ? plan.name : '';
}

export function nextUpgradePlan(sku_id: string | undefined): string {
  if (!sku_id) return '';
  const currentPlan = planData.find((plan) => plan.sku_id === sku_id);
  if (currentPlan) {
    const currentLevel = currentPlan.level;
    const nextLevel = currentLevel + 1;

    const nextPlan = planData.find((plan) => plan.level === nextLevel);
    if (nextPlan) {
      return nextPlan.name;
    }
  }
  return '';
}

export function isCardValid(cardMonth: number, cardYear: number) {
  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();
  if (currentYear > cardYear) {
    return false;
  }
  if (currentYear === cardYear) {
    if (currentMonth > cardMonth) {
      return false;
    }
    return true;
  }
  return true;
}

export function calculateRemainingDays(trialStartTime: string) {
  const startDate = dayjs(trialStartTime);
  const endDate = startDate.add(FREE_TRIAL_DURATION, 'day');
  const currentDate = dayjs();
  const remainingDays = endDate.diff(currentDate, 'day');
  return remainingDays;
}

export function getPartnerId() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);
  return partnerName;
}

export const getCardImageProps = (brand: string) => {
  switch (brand) {
    case 'visa':
      return { src: VisaCard, width: '52', height: '16' };
    case 'mastercard':
      return { src: MasterCard, width: '40', height: '30' };
    case 'amex':
      return { src: AmexCard, width: '40', height: '16' };
    case 'discover':
      return { src: DiscoverCard, width: '40', height: '30' };
    default:
      return { src: CreditCard, width: '40', height: '30' };
  }
};
