/* eslint-disable import/order */
// Core & Essential Dependencies
import * as Sentry from '@sentry/react';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

// import { homeSecurityPortalApi } from 'data/axios';
import './App.css';

// MUI Components & Theme
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// Project Configs & Contexts
import envConfig from 'configs/envConfig';
// import urls from 'configs/urls';
import { ConfigContext } from 'context';
import AppThemeProvider from 'themes/AppThemeProvider';

// Redux Hooks & Store Actions
// import { useAppDispatch, useAppSelector } from 'store/hooks';
// import { fetchUser } from 'store/reducers/userReducers';
// import { LogoutUser } from 'store/store';

// Utility Functions & Analytics
import * as amplitude from '@amplitude/analytics-browser';
import { getPartnerConfiguration, getPartnerName } from 'partner_configuration';
// import PrivacyPolicy from 'pages/privacyPolicy/PrivacyPolicy';
// import TermsOfService from 'pages/TermsAndService/TermsAndServices';
// import AuthorizeDeviceFromApp from './components/AuthorizationFromApp';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import InstaSpinner from 'components/InstaSpinner';
import { useTranslation } from 'react-i18next';
import AddPaymentMethods from './components/BillingAndInvoices/addPaymentMethods';
import BillingAndInvoiceSummary from './components/BillingAndInvoices/billingAndInvoicesSummary';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
// import LoginScreen from './views/LoginScreen/LoginScreen';
// import fallback from './views/LaunchApp/fallback';

// Privacy Policy and Terms of Service pages
const PrivacyPolicy = React.lazy(
  () => import('pages/privacyPolicy/PrivacyPolicy')
);
const TermsOfService = React.lazy(
  () => import('pages/TermsAndService/TermsAndServices')
);

// Authentication-related pages
const LoginScreen = React.lazy(() => import('./views/LoginScreen/LoginScreen'));

// Public-facing application entry points
const LaunchApp = React.lazy(() => import('views/LaunchApp'));

// 404 Not Found page
const NotFound = React.lazy(() => import('views/NotFound/NotFound'));

// Authorize device via app integration
// const AuthorizeDeviceFromApp = React.lazy(
//   () => import('./components/AuthorizationFromApp')
// );

// User account overview and settings
const UserAccount = React.lazy(() => import('pages/userAccount'));

// Account deletion request and verification
const AccountDeletionRequest = React.lazy(
  () => import('views/AccountDeletion/request')
);
const VerifyDeleteRequest = React.lazy(
  () => import('views/AccountDeletion/verifyRequest')
);

// List and details of subscriptions
const SubscriptionList = React.lazy(
  () => import('pages/subscriptions/subscriptionList')
);
const SubscriptionsDetails = React.lazy(
  () => import('pages/subscriptions/subscriptionDetails')
);

// Subscription actions: purchasing and upgrading
const BuySubscription = React.lazy(() => import('pages/buySubscription'));
const UpgradeSubscription = React.lazy(
  () => import('pages/upgradeSubscription')
);
const BasicUpgrade = React.lazy(() => import('pages/basicUpgrade'));

// Review and confirm purchase flows
const ReviewPurchase = React.lazy(() => import('pages/reviewPurchase'));

// Overview of available plans
const OurPlans = React.lazy(() => import('pages/ourPlans'));

// Adding and managing cameras
const AddCameraInfo = React.lazy(
  () => import('views/AddCameraInfo/AddCameraInfo')
);
const SingleCameraAdded = React.lazy(() => import('pages/singleCameraAdded'));
const MultiCameraAdded = React.lazy(() => import('pages/multiCameraAdded'));

// Features like skipping a trial or explaining skipping benefits
const WhySkip = React.lazy(() => import('pages/whySkip/whySkip'));
const SkippingTrial = React.lazy(
  () => import('views/SkippingTrial/SkippingTrial')
);

// Feature showcase or promotions
const Features = React.lazy(() => import('views/Features'));

// Main dashboard for logged-in users
const Dashboard = React.lazy(() => import('pages/dashboard'));

// Device and partner-specific bridge
const Bridge = React.lazy(() => import('views/Bridge'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { t: translate } = useTranslation('translation', {
    keyPrefix: 'partnerConfig'
  });
  const url = window.location.origin;
  console.log('url::', url);
  const partnerName = getPartnerName(url);
  console.log(':: partnerName', partnerName);
  // const dispatch = useAppDispatch();
  // const { isAuthenticated } = useAppSelector((state) => state.user);
  // const location = useLocation();
  const config = getPartnerConfiguration(url, translate);
  console.log('config:: ', config);
  // test
  const theme = AppThemeProvider(partnerName);

  useEffect(() => {
    // amplitude
    const amplitudeKey = envConfig('amplitudeKey');
    if (amplitudeKey) amplitude.init(amplitudeKey);

    // if (location.pathname.includes('authorize_device')) {
    //   return;
    // }
  }, []);

  return (
    <div className="App" style={{ height: '100vh' }}>
      <ErrorBoundary>
        <ConfigContext.Provider value={config}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<InstaSpinner withWrapper />}>
              <SentryRoutes>
                <Route path="launchMobileApp" Component={LaunchApp} />
                {/* <Route path="launchApp" Component={fallback} /> */}
                <Route path="login" Component={LoginScreen} />
                {/* <Route
                  path="authorize_device_from_app"
                  Component={AuthorizeDeviceFromApp}
                /> */}
                <Route
                  path="requestDeleteServices"
                  Component={AccountDeletionRequest}
                />
                <Route
                  path="verifyDeleteServices"
                  Component={VerifyDeleteRequest}
                />
                <Route path="/privacy-policy" Component={PrivacyPolicy} />
                <Route path="/terms-of-service" Component={TermsOfService} />
                <Route path="bridge" Component={Bridge} />
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" Component={Dashboard} />

                  <Route path="/skipTrial" element={<SkippingTrial />} />

                  <Route path="/promotion" Component={Features} />
                  <Route
                    path="requestDeleteServices"
                    Component={AccountDeletionRequest}
                  />
                  <Route
                    path="verifyDeleteServices"
                    Component={VerifyDeleteRequest}
                  />

                  <Route
                    path="/singleCameraAdded"
                    element={<SingleCameraAdded />}
                  />
                  <Route
                    path="/multiCameraAdded"
                    element={<MultiCameraAdded />}
                  />
                  <Route path="/reviewPurchase" Component={ReviewPurchase} />
                  <Route path="/ourPlans" Component={OurPlans} />
                  <Route path="/addCameraInfo" Component={AddCameraInfo} />
                  <Route
                    path="/billings"
                    Component={BillingAndInvoiceSummary}
                  />
                  <Route path="/billings/new" Component={AddPaymentMethods} />
                  <Route path="/userAccount" Component={UserAccount} />
                  <Route path="/buySubscription" Component={BuySubscription} />
                  <Route path="/subscriptions" Component={SubscriptionList} />
                  <Route
                    path="/subscription/:id"
                    Component={SubscriptionsDetails}
                  />
                  <Route path="/whySkipTrial" Component={WhySkip} />
                  <Route path="/upgradeBasic" Component={BasicUpgrade} />
                  <Route
                    path="/subscription/:id/upgrade"
                    Component={UpgradeSubscription}
                  />
                  <Route path="*" Component={NotFound} />
                  {/* <Route
                    path="/MySubscription"
                    Component={SubscriptionDetails}
                  /> */}
                  {/* <Route path="/planlist" Component={SubscriptionPlanContent} />
                  <Route
                    path="/payment-selections"
                    Component={PaymentOptions}
                  /> */}
                  {/* {/* <Route path="/payment-success" Component={PaymentSuccess} /> */}
                  {/* <Route path="/setupComplete" Component={SetupComplete} />
                  <Route
                    path="/subscription-functions"
                    element={<SubscriptionFunctions />}
                  /> */}
                  {/* <Route path="/why-subscribe" element={<WhySubscribe />} /> */}
                  {/* <Route
                    path="/camera-selection"
                    element={<CameraSelection />}
                  />
                  <Route
                    path="/subscription-summary"
                    element={<SubscriptionSummary />}
                  /> */}
                </Route>
                <Route path="*" Component={LoginScreen} />
              </SentryRoutes>
            </Suspense>
          </ThemeProvider>
        </ConfigContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
