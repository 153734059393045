import React, { Component, ErrorInfo } from 'react';
import { Flex } from 'styles';
import { TProps, TState_ErrorBoundry } from 'typings';
import { Typography, Button } from '@mui/material';
import { getPartnerId } from 'utils/commonFunctions';

class ErrorBoundary extends Component<TProps, TState_ErrorBoundry> {
  timer: NodeJS.Timeout | null = null;

  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false, reloadTimer: 3, isChunkError: false };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   console.error('ErrorBoundary caught an error: ', error, errorInfo);
  //   this.setState({ hasError: true });
  // }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);

    // Detect chunk loading error
    if (/Loading chunk \d+ failed/.test(error.message)) {
      this.setState({ hasError: true, isChunkError: true });
      this.startReloadCountdown();
    } else {
      // Handle other types of errors, if desired
      this.setState({ hasError: true, isChunkError: false });
    }
  }

  // Old Error Boundary
  // render() {
  //   // eslint-disable-next-line react/destructuring-assignment
  //   if (this.state.hasError) {
  //     return (
  //       <Flex justify="center" align="center" height="100%" direction="column">
  //         <Typography style={{ textAlign: 'center' }}>
  //           Something went wrong.
  //         </Typography>
  //         <Typography>Please refresh</Typography>
  //       </Flex>
  //     );
  //   }

  //   // eslint-disable-next-line react/destructuring-assignment
  //   return this.props.children;
  // }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  handleManualRefresh = () => {
    // Clear the timer and reload manually
    if (this.timer) clearInterval(this.timer);
    window.location.reload();
  };

  startReloadCountdown = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.reloadTimer === 1) {
          clearInterval(this.timer as NodeJS.Timeout);
          this.timer = null;
          window.location.reload();
          return { reloadTimer: null };
        }
        return { reloadTimer: prevState.reloadTimer! - 1 };
      });
    }, 1000);
  };

  render() {
    const { hasError, isChunkError, reloadTimer } = this.state;
    const patnerName = getPartnerId();
    if (hasError) {
      return (
        <Flex justify="center" align="center" height="100%" direction="column">
          {isChunkError ? (
            <>
              <Typography
                style={{ textAlign: 'center' }}
                variant="h6"
                aria-live="polite"
                role="alert"
              >
                Oops! Something went wrong while loading this page.
              </Typography>
              <Typography style={{ textAlign: 'center', marginBottom: '16px' }}>
                The page will reload automatically in {reloadTimer} seconds.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleManualRefresh}
              >
                Refresh Now
              </Button>
            </>
          ) : (
            <>
              <Typography
                style={{ textAlign: 'center' }}
                variant="h6"
                aria-live="polite"
                role="alert"
              >
                An unexpected error occurred.
              </Typography>
              <Typography style={{ textAlign: 'center', marginBottom: '16px' }}>
                Please try refreshing the page or contact our support team at{' '}
                {patnerName === 'luna'
                  ? 'lunasupport@lunahome.com or +1 (855) 604 5862'
                  : 'support@instavision.ai or +1 (877) 916 3025'}{' '}
                if the issue persists.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={this.handleManualRefresh}
              >
                Refresh Page
              </Button>
            </>
          )}
        </Flex>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
