/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled as styledMUI,
  useTheme
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as ActiveDevicesDashboardIcon } from 'assets/active-devices-dashboard.svg';
import { ReactComponent as BillingAndInvoiceIcon } from 'assets/billing-and-invoice.svg';
import { ReactComponent as DrawerMenuIcon } from 'assets/drawer-menu-icon.svg';
import { ReactComponent as OurPlanIcon } from 'assets/our-plan-icon.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/subscription-icon.svg';
import { ReactComponent as UserPortalIcon } from 'assets/user-portal.svg';
import { ConfigContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { TNavData_SideBar, TProps_SideBar } from 'typings';
import i18next from '../../i18n/i18n';

const t = i18next.getFixedT(null, 'translation', 'layout.sidebar');

const drawerWidth = isMobile ? '100%' : 340;

export const getLeftNavData = () => {
  return [
    {
      name: t('dashboard'),
      icon: <ActiveDevicesDashboardIcon />,
      link: '/dashboard',
      macthRoutes: ['/dashboard']
    },
    {
      name: t('account'),
      icon: <UserPortalIcon />,
      link: '/userAccount',
      macthRoutes: ['/userAccount']
    },
    {
      name: t('subscription'),
      icon: <SubscriptionIcon />,
      link: '/subscriptions',
      macthRoutes: ['/subscriptions', '/subscription']
    },
    {
      name: t('billing'),
      icon: <BillingAndInvoiceIcon />,
      link: '/billings',
      macthRoutes: ['/billings']
    },
    {
      name: t('ourPlans'),
      icon: <OurPlanIcon />,
      link: '/ourPlans',
      macthRoutes: ['/ourPlans']
    }
  ];
};

const DrawerHeader = styledMUI('div')(({ theme }) => ({
  display: 'flex',
  // gridTemplateColumns: '0.5fr auto 0.5fr',
  justifyContent: 'space-between',
  alignItems: 'center',
  // marginBottom: theme.spacing(1),
  padding: '12px 20px 7px 12px',
  backgroundColor: theme.palette.background.default,
  minHeight: theme.spacing(10),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

function SideBar(props: TProps_SideBar) {
  const { setOpenSideBar, openSideBar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const config = useContext(ConfigContext);
  const Logo = config.logo;

  const leftNavData = getLeftNavData();

  const index = leftNavData.findIndex((el) => {
    const found = el.macthRoutes.find((route) => {
      const res = location.pathname.search(route);
      return res >= 0;
    });
    return found;
  });
  const [selectedNav, setSelectedNav] = useState<TNavData_SideBar>(
    leftNavData[index]
  );

  useEffect(() => {
    setSelectedNav(leftNavData[index]);
  }, [index]);

  const onSideNavClick = (nav: TNavData_SideBar) => {
    setSelectedNav(nav);
    navigate(nav?.link);
    if (isMobile) {
      setOpenSideBar(false);
    }
  };

  const logoClick = () => {
    navigate('/dashboard');
    if (isMobile) {
      setOpenSideBar(false);
    }
  };

  const list = () => {
    return (
      <List
        sx={{
          marginTop: theme.spacing(3)
        }}
      >
        {leftNavData.map((nav) => (
          <ListItem key={nav?.name} disablePadding>
            <ListItemButton
              selected={selectedNav?.name === nav.name}
              sx={{
                borderRadius: '20px',
                '&.Mui-selected': {
                  background:
                    'linear-gradient(143.2deg, #DEDEDE 7.09%, #F5F5F5 89.29%, #FFFFFF 100%)',
                  border: '1px solid',
                  borderImageSource:
                    'linear-gradient(141.49deg, #C4C4C4 -5.52%, #FFFFFF 92.61%)'
                },
                '&:hover': {
                  background:
                    'linear-gradient(143.2deg, #DEDEDE 7.09%, #F5F5F5 89.29%, #FFFFFF 100%)',
                  border: '0.5px solid',
                  borderImageSource:
                    'linear-gradient(318.14deg, #D5D5D5 9.39%, #FFFFFF 88.07%)'
                }
              }}
              onClick={() => onSideNavClick(nav)}
            >
              <ListItemIcon
                sx={{
                  padding: 0,
                  minWidth: 0,
                  marginRight: '12px',
                  width: '8px',
                  height: '8px',
                  boxShadow: '0px 0px 2px 0px #00000040 inset',
                  border: '0.25px solid #BEBEBE',
                  backgroundColor:
                    selectedNav?.name === nav.name
                      ? theme.palette.primary.main
                      : '#E8E8E8',
                  borderRadius: '50%'
                }}
              />
              <ListItemText primary={nav.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  if (location.pathname === '/addCameraInfo') {
    return null;
  }

  return (
    <Drawer
      sx={{
        padding: '24px',
        backgroundColor: (theme) => `${theme.palette.background.default}`,
        color: '#333333',
        width: drawerWidth,
        // flexShrink: 0,
        position: 'absolute',
        height: '100vh',
        top: '0px',
        '& .MuiDrawer-paper': {
          width: drawerWidth
          // padding: '24px 0'
          // top: isMobile ? 60 : 0
        }
      }}
      // variant={isMobile ? 'temporary' : 'permanent'}
      // anchor="left"
      open={openSideBar}
      variant="persistent"
      anchor={isMobile ? 'right' : 'left'}
      elevation={32}
    >
      <DrawerHeader>
        {isMobile ? <div style={{ width: '40px' }} /> : <div />}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={Logo}
          alt="luna-logo"
          width="60"
          height="60"
          onClick={logoClick}
        />
        {isMobile ? (
          <IconButton onClick={() => setOpenSideBar(false)} sx={{ p: 0 }}>
            <DrawerMenuIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </DrawerHeader>
      <Divider />
      <SideBarNav>{list()}</SideBarNav>
    </Drawer>
  );
}
export default SideBar;

const SideBarNav = styledMUI('div')`
  width: 100%;
  padding: 24px;
`;
